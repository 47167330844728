<template>
    <div class="text-center">
        <v-dialog v-model="dialog" persistent width="500">

            <v-card>
                <v-card-title class="headline grey lighten-2">
                    Загрузка изображений
                </v-card-title>

                <v-card-text>
                    <div v-for="(img, index) in imagesArray">
                        {{index}}
                        <p><b>{{img.name}}</b> - {{img.status}}</p>
                        <v-progress-linear :value="img.loading"></v-progress-linear>
                    </div>

                </v-card-text>

                <v-divider></v-divider>


            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { storage, db } from '../../firebase';

    export default {
        name: "ImageUploader",

        data () {
            return {
                dialog: false,
                imagesArray: [],
                imagesArrayStatus: [],
                parsedHtml: '',
            }
        },
        computed: {
            collection: function () {
                return this.$route.params.collection
            },
            id : function () {
                return this.$route.params.id
            },
        },

        methods: {
            async parseImages(html) {
                this.parsedHtml = this.findImages(html);
                // console.log(html);
                if (this.imagesArray.length > 0) {
                    this.dialog = true;
                    await this.uploadImages(this.imagesArray).then(downloadedUrls => {
                        this.setImage(downloadedUrls).then(html => {
                            this.dialog = false;
                            return html
                        })
                    });
                    return this.parsedHtml

                } else {
                    return this.parsedHtml
                }

            },
            stringFromCurrentHtmlObject(value) {
                return new DOMParser().parseFromString(value, 'text/html');
            },
            findImages(value) {
                let that = this;
                let html = this.stringFromCurrentHtmlObject(value)
                let imagesArray = [];
                that.imagesArray = [];
                that.imagesArrayStatus = [];

                let images = html.getElementsByTagName('img');
                if (images.length > 0) {
                    images.forEach(image => {
                        let filename = image.getAttribute('data-filename');

                        if (filename && image.id !== filename) {
                            //Удаляем домен и после ?d
                            filename = filename.split('/').pop().replace(/[\#\?].*$/,'')

                            const spitedName = that.getFilenameAndExtension(filename)
                            const namePlusRandom = spitedName[0] + '-' + that.random(6) + '.' + spitedName[1];
                            image.id = namePlusRandom
                            image.setAttribute('data-filename', namePlusRandom)
                            imagesArray.push({name: namePlusRandom, image: dataURItoBlob.call(that, image.src)})
                        }
                    })
                }
                that.imagesArray = imagesArray;
                return html.body.innerHTML;


            },
            uploadImages(images) {
                let error_url = '/assets/minimalist-blocks/preview/preview.png';
                let that = this;
                let downloadedUrls = []

                return new Promise(resolve => {
                    images.forEach((image, index) => {
                        that.$set(that.imagesArray[index], 'status', 'Загрузка');
                        that.$set(that.imagesArray[index], 'loading', 0);
                        // Удаляем из имени урл и все после ?
                        image.name = image.name.split('/').pop().replace(/[\#\?].*$/,'');
                        const storageRef = storage.ref(this.collection + '/' + this.id + '/' + image.name).put(image.image);
                        storageRef.on(`state_changed`, snapshot => {
                                that.imagesArray[index].loading = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            }, error => {
                                that.imagesArrayStatus.push('error')
                                that.imagesArray[index].status = 'Ошибка загрузки';
                                downloadedUrls.push({name: image.name, url: error_url})
                                if (images.length === that.imagesArrayStatus.length) {
                                    resolve(downloadedUrls);
                                }
                            },
                            () => {
                                storageRef.snapshot.ref.getMetadata()
                                    .then((e) => {
                                        that.imagesArray[index].status = 'Оптимизация изображения';
                                        const thumbnail = that.getFilenameAndExtension(e.name)[0]+'_1600x1600.'+that.getFilenameAndExtension(e.name)[1];

                                        function getOptimizedImg() {
                                            console.log('getOptimizedImg')
                                            let pathReference = storage.ref(that.collection + '/' + that.id + '/' + thumbnail);
                                            pathReference.getDownloadURL()
                                                .then((url) => {
                                                    // console.log((e.name, url));
                                                    downloadedUrls.push({name: e.name, url: url})
                                                    that.imagesArrayStatus.push('done')
                                                    that.imagesArray[index].status = 'Готово';
                                                    if (images.length === that.imagesArrayStatus.length) {
                                                        resolve(downloadedUrls);
                                                    }
                                                }).catch((e) => {
                                                setTimeout(getOptimizedImg, 3000);
                                            });
                                        }
                                        setTimeout(getOptimizedImg, 3000);

                                    });
                            }
                        );
                    })
                });


            },
            setImage(downloadedUrls) {
                let html = this.stringFromCurrentHtmlObject(this.parsedHtml);
                let that = this;
                let arr = [];

                console.log('setImage length:'+downloadedUrls.length);
                return new Promise(resolve => {
                    downloadedUrls.forEach(im => {
                        const elImg = html.getElementById(im.name)
                        elImg.setAttribute('src', im.url)
                        elImg.removeAttribute('id')
                        elImg.removeAttribute('data-filename')
                        arr.push('d')
                        // console.log(arr.length + ' : ' + html.body.innerHTML)
                        if (arr.length === downloadedUrls.length) {
                            that.parsedHtml = html.body.innerHTML;
                            resolve(html.body.innerHTML)
                            // console.log('fin : ' + html.body.innerHTML)
                        }
                    });
                })

            },
        }
    }


    function dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(dataURI.split(',')[1]);
        } else {
            byteString = unescape(dataURI.split(',')[1]);
        }
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
    }
</script>

<style scoped>

</style>
