import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase/app'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Settings',
    meta: {auth: true},
    component: () => import( '../views/Settings.vue')
  },
  {
    path: '/admins',
    name: 'Admins',
    meta: {auth: true},
    component: () => import( '../views/Admins.vue')
  },
  {
    path: '/media',
    name: 'Media',
    meta: {auth: true},
    component: () => import( '../views/Media.vue')
  },
  {
    path: '/menu',
    name: 'Menu',
    meta: {auth: true},
    component: () => import( '../views/Menu.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    meta: {auth: true},
    component: () => import( '../views/Settings.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: {auth: false},
    component: () => import( '../views/Login.vue')
  },
  {
    path: '/:collection',
    name: 'Pages',
    meta: {auth: true},
    component: () => import( '../views/Pages.vue')
  },
  {
    path: '/:collection/:id',
    name: 'Editor',
    meta: {auth: true},
    component: () => import( '../views/Editor.vue')
  },
  {
    path: '/:collection/:id/:lang',
    name: 'Editor',
    meta: {auth: true},
    component: () => import( '../views/Editor.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const currUser = firebase.auth().currentUser
  const requireAuth = to.matched.some(record => record.meta.auth)

  if (requireAuth && !currUser) {
    next('login')
  } else {
    next()
  }
})

export default router
