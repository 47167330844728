<template>
    <div>
        <div class="alert_container">
            <v-alert style="position: absolute; width: 100%"
                     :value="alert.status"
                     :color="alert.color"
                     :type="alert.type"
                     transition="scale-transition"
            >{{alert.message}}</v-alert>
        </div>

    </div>
</template>

<script>
    export default {
        name: "Alerts",

        computed: {
            alert() {
                return this.$store.state.alert;
            }
        }
    }
</script>

<style scoped>
.alert_container {
    z-index: 999;
    position: relative;
    width: 100%;
}
</style>
