import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

import { firestorePlugin } from 'vuefire'
Vue.use(firestorePlugin)

import AsyncComputed from 'vue-async-computed'
Vue.use(AsyncComputed)

import {db} from './firebase';
import firebase from "firebase";

Vue.prototype.$db = db;


Vue.config.productionTip = false;

let app;
firebase.auth().onAuthStateChanged(() => {
  if(!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
  }
});


Vue.mixin({
  methods: {
    cloneObject: function (obj) {
      return JSON.parse(JSON.stringify((obj)))
    },
    compareObjects(obj1, obj2) {
      return JSON.stringify(obj1) !== JSON.stringify(obj2)
    },

    randomID: function () {
      var length = 8;
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },


    getFilenameAndExtension: function (pathfilename){

      var filenameextension = pathfilename.replace(/^.*[\\\/]/, '');
      var filename = filenameextension.substring(0, filenameextension.lastIndexOf('.'));
      var ext = filenameextension.split('.').pop();

      return [filename, ext];

    },

    random:  function (length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }

  },
});

