
export function initButtons() {
    function clickBody(e) {
        const addBtn = document.getElementsByClassName('elm-add')[0];
        if (addBtn) addBtn.remove()
        const tool = document.getElementsByClassName('is-column-tool')[0];
        if (tool) tool.remove()
        const editImage = document.getElementsByClassName('image-edit')[0];
        if (editImage) editImage.remove()


    }
    document.body.addEventListener("click", clickBody)
}



export function addButtons(event) {
    console.log(event.path[0].closest("div"))
    clearButtons()
    if (arrayIncludesClass(event.path[0], 'big-title-container') || arrayIncludesClass(event.path[0], 'title')) {
        const inner = '<button class="extra-btn"><img src="/assets/contentbuilder/l-r.png" alt="Слева" id="left-right"></button><button class="extra-btn"><img src="/assets/contentbuilder/c-b.png"  alt="Справа" id="centre-bottom"></button>'
        createButtons(inner, event).then(e => {
            actionsForTitle(event)
        })
    }

    if (Array.from(event.path[0].closest("div").classList).includes('image')) {
        const inner = '<button class="extra-btn" id="fixed">Fixed size</button><button class="extra-btn" id="del">Удалить</button><button class="extra-btn" id="add">Добавить</button><button class="extra-btn" id="l">L</button><button class="extra-btn" id="m">M</button><button class="extra-btn" id="s">S</button><button class="extra-btn" id="xs">XS</button>'
        createButtons(inner, event).then(e => {
            actionsForImage(event)
        })
    }

    // if (Array.from(event.path[0].closest(".block").classList).includes('block')) {
    //     const inner = '<button class="extra-btn" id="l">L</button><button class="extra-btn" id="m">M</button><button class="extra-btn" id="s">S</button><button class="extra-btn" id="xs">XS</button>'
    //     createButtons(inner, event).then(e => {
    //         actionsForImage(event)
    //     })
    // }
}

function arrayIncludesClass(el, cls) {
    return Array.from(el.classList).includes(cls)
}

function clearButtons() {
    const btns = document.getElementsByClassName('extra-btn')

    Array.from(btns).forEach(e => {
        e.remove()
    });
    let f = document.getElementById("first")
    if (f) f.remove()
}
function createButtons(inner, event) {
    return new Promise((resolve, reject) => {
        setTimeout(function () {
            let tool = document.getElementsByClassName('is-element-tool')[0]
            let newButs = document.createElement("div")
            newButs.innerHTML = inner;
            let first = document.createElement("div")
            first.id = 'first'
            first.style.marginLeft = '-'+newButs.children.length*25+'px';

            Array.from(newButs.children).forEach(el => {
                tool.prepend(el)
            });
            tool.prepend(first)

            resolve()
        }, 0);
    });

}

function actionsForTitle(ev) {
    let index = 0;
    if (Array.from(ev.path[0].classList).includes('title')) index = 1;

    function leftRight() {
        if (Array.from(ev.path[index].classList).includes('right')) {
            ev.path[index].classList.remove("right")
        } else {
            ev.path[index].classList.add('right')
        }
    }
    document.getElementById('left-right').addEventListener("click", leftRight)


    function centreBottom() {
        if (Array.from(ev.path[index].classList).includes('end')) {
            ev.path[index].classList.remove("end")
        } else {
            ev.path[index].classList.add('end')
        }
    }
    document.getElementById('centre-bottom').addEventListener("click", centreBottom)
}

function actionsForImage(ev) {
    const index = 0;
    function l() {
        ev.path[index].closest("div").classList.remove("half")
        ev.path[index].closest("div").classList.remove("medium")
        ev.path[index].closest("div").classList.remove("small")
    }
    document.getElementById('l').addEventListener("click", l)


    function m() {
        ev.path[index].closest("div").classList.remove("half")
        ev.path[index].closest("div").classList.remove("medium")
        ev.path[index].closest("div").classList.remove("small")

        ev.path[index].closest("div").classList.add('medium')
    }
    document.getElementById('m').addEventListener("click", m)

    function s() {
        ev.path[index].closest("div").classList.remove("half")
        ev.path[index].closest("div").classList.remove("medium")
        ev.path[index].closest("div").classList.remove("small")

        ev.path[index].closest("div").classList.add('half')
    }
    document.getElementById('s').addEventListener("click", s)

    function xs() {
        ev.path[index].closest("div").classList.remove("half")
        ev.path[index].closest("div").classList.remove("medium")
        ev.path[index].closest("div").classList.remove("small")

        ev.path[index].closest("div").classList.add('small')
    }
    document.getElementById('xs').addEventListener("click", xs)


    function fixedSize() {
        if (Array.from(ev.path[index].closest("div").classList).includes('fixed')) {
            ev.path[index].closest("div").classList.remove("fixed")
        } else {
            ev.path[index].closest("div").classList.add('fixed')
        }
    }
    if (document.getElementById('fixed')) document.getElementById('fixed').addEventListener("click", fixedSize)


    function add() {
        // let img = document.createElement('div');
        // img.innerHTML = '<div class="is-tool" style="display: unset"><img src="/assets/minimalist-blocks/preview/preview.png" alt="Baraban"></div>';
        //
        // document.getElementById('_cbhtml').append(img.children[0])

        let img = document.createElement('div');
        img.innerHTML = '<div class="image half"><img src="/assets/minimalist-blocks/preview/preview.png" alt="Baraban"></div>';

        ev.path[index].closest("div").parentElement.append(img.children[0])
    }
    if (document.getElementById('add')) document.getElementById('add').addEventListener("click", add)

    function del() {
        ev.path[index].closest("div").remove()
    }
    if (document.getElementById('del')) document.getElementById('del').addEventListener("click", del)
}
