<template>
    <div>
        <transition name="fade">
            <div v-if="loading" class="note">Обновления кэша может занять несколько минут.</div>
        </transition>
        <v-btn :loading="loading" v-on:click="clearCash" block  :color="loading ? 'yellow' : 'error'" style="text-transform: unset; letter-spacing: unset"  elevation="0"><v-icon left dark>mdi-update</v-icon>Очистить кэш</v-btn>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        name: "ClearCash",
        data() {
            return {
                loading: false
            }
        },
        methods: {
            async clearCash() {
                // axios.post('')
                this.loading = true;
                let that = this;
                axios.get('https://us-central1-baraban-art.cloudfunctions.net/ssrapp', {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                    },
                }).then(res => {
                    console.log(res);
                    that.loading = false;
                    that.$store.commit('alert', { message: 'Кэш успешно обновлен'})
                })
                    .catch(e => {
                        that.loading = false;
                        that.$store.commit('alert', {type: 'error', message: e})

                    })



                // setTimeout(function () {
                //     that.loading = false;
                // }, 1000)
            }
        }
    }
</script>

<style scoped>
    .note {
        text-align: center;
        font-size: 14px;
        opacity: .8;
        line-height: 1.3em;
        margin-bottom: 16px;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
        opacity: 0;
    }

</style>
