import Vue from 'vue'
import Vuex from 'vuex'
import { db } from '../firebase';
import firebase from "firebase";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    alert: {
      status: false,
      message: 'Страница обновлена.',
      color: 'green',
      type: 'success'
    },
    languages: null,
    count: null,
    user: null,
    project_categories: null,
    imageSizes: ['300x300', '900x900', '1600x1600']
  },
  mutations: {
    alert(state, data) {
      state.alert = {
        status: true,
        message: data['message'],
        color: data['type'] !== 'error' ? 'green' : 'red',
        type: data['type'] !== 'error' ? 'success' : 'error',

      };
      let t = 0;
      let timer = setInterval(function () {
        if (t < 500) {
          t++;
        } else {
          clearTimeout(timer)
          state.alert = {status: false};
        }
      }, 1);
    },
    setLanguages(state, data) {
      state.languages = data;
    },
    setCounter(state, data) {
      state.count = data
    },
    user(state, data) {
      state.user = data
    },
    categories(state, data) {
      state.project_categories = data
    }
  },
  actions: {
    async getLanguages({dispatch, commit}) {
      const doc = await db.collection('settings').doc('languages').get();
      if (!doc.exists) {
        alert('Ошибка загрузки настоек')
      } else {
        commit('setLanguages', doc.data())
      }
    },
    async getCounter({dispatch, commit}) {
      const doc = await db.collection('settings').doc('counter').get();
      if (!doc.exists) {
        alert('Ошибка загрузки счетчика')
      } else {
        commit('setCounter', doc.data()['count'])
      }
    },
    getUser({dispatch, commit}) {
      const user = firebase.auth().currentUser;
      commit('user', user)
    },
    async getCategories({dispatch, commit}) {
      const doc = await db.collection('settings').doc('project_categories').get();
      if (doc.exists) {
        commit('categories', doc.data())
        return  doc.data()
      } else {
        return null;
      }
    },
  },
  getters: {
    count: s => s.count,
    languages: s => s.languages,
    user: s => s.user,
  },
  modules: {
  },
})
