// import * as firebase from 'firebase';
import firebase from 'firebase';

// import firebase from 'firebase/app'
// import 'firebase/firestore'


firebase.initializeApp({
    apiKey: "AIzaSyBaC7DFsfcin3ZnfnIcbxtXZt5lijmtQ0c",
    authDomain: "baraban-art.firebaseapp.com",
    projectId: "baraban-art",
    storageBucket: "baraban-art.appspot.com",
    messagingSenderId: "981547616164",
    appId: "1:981547616164:web:63b0860e9aef8173942331",
    measurementId: "G-W428F76XS0"

    // databaseURL: "https://the-fame-apps.firebaseio.com",
});

const { Timestamp, GeoPoint, FieldValue } = firebase.firestore;
export { Timestamp, GeoPoint, FieldValue };

export const db = firebase.firestore();
export const storage = firebase.storage();
export const increment = firebase.firestore.FieldValue.increment(1);
export const decrement = firebase.firestore.FieldValue.increment(-1);


// async function addNewPage() {
//     goCount().then(function (r) {
//         console.log(r)
//     });
//     // db.collection(collection).add({
//     //     id: 0,
//     //     languages: {
//     //         en: {
//     //             title: '',
//     //             slug: '',
//     //             description: '',
//     //             thumbnail: '',
//     //             date: '',
//     //             publish: false,
//     //             html: ''
//     //         },
//     //         ru: {
//     //             title: '',
//     //             slug: '',
//     //             description: '',
//     //             thumbnail: '',
//     //             date: '',
//     //             publish: false,
//     //             html: ''
//     //         }
//     //     }
//     // })
// }
// export const addd = addNewPage();
//
//     async function goCount() {
//     const doc = await db.collection('settings').doc('counter').get();
//     if (!doc.exists) {
//         return
//     } else {
//         return doc.data()
//     }
// }
