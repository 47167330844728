<template>
    <v-navigation-drawer app v-if="user()">
        <v-list-item>
            <v-list-item-content>
                <v-row>
                    <v-col><v-img src="@/assets/logo.svg" max-width="100"></v-img></v-col>
                    <v-col style="text-align: right"><v-btn icon ><v-icon>mdi-logout-variant</v-icon></v-btn></v-col>
                </v-row>
            </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense nav>
            <v-list-item v-for="item in items" :key="item.title" link :to="item.slug">
                <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>


        <template v-slot:append>
            <div class="pa-2">
                <ClearCash/>
            </div>
        </template>
    </v-navigation-drawer>
</template>

<script>
    import firebase from "firebase";
    import ClearCash from "./ClearCash";

    export default {
        name: "Sidebar",
        components: {ClearCash},
        data () {
            return {
                items: [
                    // { title: 'Dashboard', icon: 'mdi-view-dashboard', slug: '/' },
                    // { title: 'Билдер', icon: 'mdi-image', slug: '/' },
                    { title: 'Главная', icon: 'mdi-home', slug: '/main' },
                    { title: 'Страницы', icon: 'mdi-book-open-page-variant', slug: '/pages' },
                    { title: 'Проекты', icon: 'mdi-cards', slug: '/projects' },
                    { title: 'Меню', icon: 'mdi-menu', slug: '/menu' },
                    { title: 'Медиа', icon: 'mdi-folder-multiple-image', slug: '/media' },
                    { title: 'Администраторы', icon: 'mdi-account-multiple', slug: '/admins' },
                    { title: 'Настройки', icon: 'mdi-cog', slug: '/settings' },
                ],
                right: null,
            }
        },
        methods: {
            user() {
                return this.$store.getters.user;
            },
            async logOut() {
                const that = this;
                await firebase.auth().signOut().then(() => {
                    that.$store.commit('user', null)
                    that.$router.push('/login')
                })
            }
        },
    }
</script>

<style scoped>

</style>
