<template>
    <div class="d-flex justify-center align-center" style="height: 80vh">
        <v-progress-circular indeterminate :size="70" :width="7"></v-progress-circular>
    </div>
</template>

<script>
    export default {
        name: "Loader"
    }
</script>

<style scoped>

</style>
