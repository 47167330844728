<template>
    <div>
        <ImageUploader ref="imageUploader"   />
        <div v-bind:class="{ disabled: !isBuilderInt }">

            <div class="contentbuilder" id="contentbuilder" v-html="value" v-mutate="onMutate" ></div>
        </div>
    </div>
</template>

<script>
    import ImageUploader from "./ui/ImageUploader";
    import {addButtons, initButtons} from "../contentbuilder-extra-buttons"
    export default {
        name: "ContentBuilder",
        components: {ImageUploader},
        data() {
            return {
                builder: '',
                isBuilderInt: false,
                currentHtml: null,
                updatedHtml: null,
                imagesArray: [],
            };
        },
        props: ['value'],
        methods: {
            showHtml() {
                this.builder.viewHtml()
            },
            onMutate() {
                this.updatedHtml = this.builder.html();
                this.$emit('changed', this.isChanged())
            },
            isChanged() {
                return this.currentHtml === this.updatedHtml;
            },

            getHtml() {
                let that = this;
                return new Promise(function (resolve) {
                    let html = that.builder.html();
                    that.isBuilderInt = false

                    that.$refs.imageUploader.parseImages(html).then(function (result) {
                        that.$emit('input', result)
                        that.updateBuilder()
                        resolve()
                    })
                })

            },
            updateBuilder() {
                this.isBuilderInt = false;
                let that = this;
                let t = 0;
                let timer = setInterval(function () {
                    if (t < 50) {
                        t++;
                    } else {
                        clearTimeout(timer)
                        that.isBuilderInt = true;
                        destroyContentBuilder.call(that)
                        intContentBuilder.call(that)
                        that.setCurrentHtml()
                    }
                }, 1);
            },
            setCurrentHtml() {
                this.currentHtml = this.builder.html()

            },
        },
        mounted() {
            this.updateBuilder()
            initButtons()
        },
        beforeDestroy() {
            destroyContentBuilder.call(this)
        },
    }

    function intContentBuilder() {
        // console.log('intContentBuilder')
        if(this.builder.builderStuff) return;
        this.builder = new ContentBuilder({
            container: '.contentbuilder',
            colors: ["#ff8f00",],
            imageSelect: '/media',
            buttons: [  'formatPara', 'bold', 'list',  'align', 'createLink',  'html', '|', 'undo', 'redo'],
            // buttons: ['bold', 'italic', 'underline', 'formatting', 'color', 'align', 'textsettings', 'createLink', 'tags', 'more' , '|', 'undo', 'redo']
            // fileSelect: 'files.html',
            // buttonsMore: ['icon', 'image', '|',  'italic', 'underline', 'font', 'formatPara', '|', 'html', 'preferences'],
            buttonsMore: [],
            // plugins: ['preview','wordcount', 'helloworld', 'slider'],
            defaultSnippetCategory: 120,
            snippetCategories: [[120,"Все"], [121, 'Главная']],
            snippetAddTool: false,
            // onAdd: function (html) {
            //     console.log(html)
            //     html = html.replace('{custom tag}', 'your content');
            //     return html;
            // },
            onContentClick: function (event) {
                addButtons(event)
            }
        });
    }

    function destroyContentBuilder() {
        // console.log('destroyContentBuilder')

        if(this.builder != null && !this.builder.builderStuff) return;
        this.builder.destroy();
    }
    function random(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
    function dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(dataURI.split(',')[1]);
        } else {
            byteString = unescape(dataURI.split(',')[1]);
        }
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
    }

</script>
<style>
    .extra-btn {
        width: auto!important;
        padding: 0 4px!important;
        height: 24px;
        background-color: rgba(243,243,243,0.9)!important;
    }
    .extra-btn img {
        width: 16px;
        height: 16px;
    }
    :root {
        --black-color: black;
        --offset-x: 80px;
        --gap: 30px;
        --coll: calc(((100vw - var(--offset-x)* 2) / 5));
        --gray: #F2F2F2;
    }
</style>
<style scoped>
    .contentbuilder {
        margin-bottom: 20vh;
    }
    >>> .column.full {
        width: 100%;
    }


    >>> * {
        margin: 0;
        padding: 0;
    }
    >>>  body, >>> pre {
        font-family: Walsheim, sans-serif;
        color: var(--black-color);
        font-size: 18px;
        line-height: 1.4em;
        background-color: white;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        overflow-x: hidden;
        letter-spacing: -0.02em;
    }
    >>> a {
        color: var(--black-color);
        text-decoration: none;
    }
    >>> p {
        margin-bottom: 0.6em;
        background-color: unset!important;
    }
    >>> img {
        max-width: 100%;
        object-fit: cover;
    }

    >>> h1:first-child, >>> h2:first-child, >>> h3:first-child, >>> h4:first-child, >>> h5:first-child {
        margin-top: 0;
    }
    >>> h1, >>> h2, >>> h3, >>> h4, >>> h5 {
        text-transform: uppercase;
        letter-spacing: -0.02em;
        font-style: normal;
        font-weight: normal;
        line-height: 1.2em;
        margin: 1em 0 0.6em;
        background-color: unset!important;
    }
    >>> h1 {
        font-size: 48px;
    }
    >>> h2 {
        font-size: 36px;
    }
    >>>  h3 {
        font-size: 30px;
    }
    >>> h4 {
        font-size: 24px;
    }
    >>>  h5 {
        font-size: 18px;
    }
    >>> .big-title-container {
        height: calc(100vh - 40px - 30vh);
        width: 100%;
        /*background-color: #888888;*/
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: 550px;
    }
    >>> .title {
        width: 100%;
        font-style: normal;
        font-weight: normal;
        display: inline-block;
        font-size: 156px;
        line-height: 80%;
        letter-spacing: -0.02em;
        text-transform: lowercase;
        margin-bottom: 0;
        text-align: left!important;
        margin-left: -0.08em;
        margin-right: 0.08em;
    }

    >>> .big-title-container.right > .title {
        text-align: right!important;
        margin-left: 0.08em;
        margin-right: -0.08em;
    }
    >>> .big-title-container.end > .title {
        margin-bottom: 0.4em;
        align-self: flex-end;
    }

    >>> .main-container {
        position: relative;
        width: calc(var(--coll)*4 - var(--gap)/2);
        margin: 0 var(--offset-x) 0 auto;
    }

    >>> .main-grid-container {
        margin-left: calc(var(--gap) / 2 * -1);
        margin-right: calc(var(--gap) / 2 * -1);
        /*line-height: 0;*/
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    >>> .main-grid-container > .big-title-container {
        width: 100%;
        margin-left: calc(var(--gap) / 2);
        margin-right: calc(var(--gap) / 2);

    }


    >>> .image {
        height: auto;
        align-self: start;
        margin-bottom: calc(var(--gap));
        width: calc(100% - var(--gap))!important;
        margin-right:  calc(var(--gap)/2);
        margin-left: calc(var(--gap)/2);
    }
    /*>>> .image:last-child {*/
    /*    margin-bottom: 0;*/
    /*}*/
    >>> .image img {
        width: 100%!important;
        background-color: var(--gray);
        min-width: 100%;
        min-height: 100px;
        object-fit: cover;
    }
    >>> .medium {
        width: calc(75% - var(--gap))!important;
    }
    >>> .half {
        width: calc(50% - var(--gap))!important;
    }

    >>> .small {
        width: calc(25% - var(--gap))!important;
    }
    >>> .image.fixed img{
        height: calc((var(--coll)*4 - var(--gap)/2 ) * 0.66)!important;
    }
    >>> .image.medium.fixed img{
        height: calc((var(--coll)*3 - var(--gap)/2 ) * 0.66)!important;
    }
    >>> .image.half.fixed img{
        height: calc((var(--coll)*2 - var(--gap)/2 ) * 1.4)!important;
    }
    >>> .image.small.fixed img{
        height: calc((var(--coll)*1 - var(--gap)/2 ) * 1)!important;
    }

    >>> .block {
        margin-top: var(--offset-x);
        margin-bottom: var(--offset-x);
    }

    >>> .coll {
        display: inline-block;
        width: var(--coll);
        height: 100px;
        background-color: silver;
    }


    >>> .page-cover-image {
        object-fit: cover;
        position: absolute;
        top: calc(var(--offset-x) * 1);
        right: 0;
        width: calc((var(--coll)*4 - var(--gap)/2) / 2 - var(--gap)/2);
        height: calc(100vh - var(--offset-x) * 2);
        background-color: var(--gray);
    }

    >>> .flex-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: calc(var(--gap) / 2 * -1);
        margin-right: calc(var(--gap) / 2 * -1);
        position: relative;
    }

    >>> .cover-image {
        object-fit: cover;
        margin-top: calc(var(--offset-x) * 1);
        margin-bottom: calc(var(--offset-x) * 1);
        height: calc(100vh - var(--offset-x) * 2);
        width: 100%;
        background-color: var(--gray);
    }

    >>> .contact-content {
        position: absolute;
        bottom: 35vh;
    }


    >>> .mt-0 {
        margin-top: 0;
    }
    >>> .mb-0 {
        margin-bottom: 0;
    }


    /*Video block Видео*/
    >>> .block.medium.embed-responsive {
        height: calc(var(--coll) * 1.5);
    }



</style>
<style>



</style>
