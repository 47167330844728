<template>
  <v-app v-if="loader()">
    <Sidebar/>
    <v-main>
      <v-container>
        <Alerts/>
        <router-view></router-view>
      </v-container>

    </v-main>

  </v-app>
  <Loader v-else />

</template>

<script>
import ContentBuilder from './components/ContentBuilder.vue'
import Sidebar from './components/ui/Sidebar.vue'
import Alerts from "./components/ui/Alerts";
import Loader from "./components/ui/Loader";


export default {
  name: 'App',

  components: {
    Loader,
    Alerts,
    ContentBuilder,
    Sidebar
    // HelloWorld,
  },

  data: () => ({
    //
  }),

  methods: {
    loader() {
      const languages = this.$store.getters.languages != null
      const count = this.$store.getters.count != null
      return languages && count
    }
  },
  mounted() {
    this.$store.dispatch('getLanguages')
    this.$store.dispatch('getCounter')
    this.$store.dispatch('getUser');
  }
};
</script>

